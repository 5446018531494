import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import LandschaftenNotation from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/landschaften-notation"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/landschaften-und-orte/loesung" />
      }
    >
      <Seo title="Landschaften & Orte markieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Landschaften & Orte markieren
        </Heading>
        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  courseId: "reiseerinnerung",
                  chapterId: "01-suedfrankreich",
                  taskId: "landschaften-und-orte",
                  answer: notations,
                })
              }}
            >
              <LandschaftenNotation />
            </NotationGroup>
          }
          sidebar={
            <Note variant="task">
              Welche Ortsangaben und Landschaftsbeschreibungen kommen in dem
              Gedicht vor? Suche und markiere sie, indem du sie anklickst.
              Klicke erneut auf ein Wort, um die Markierung wieder zu entfernen.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
